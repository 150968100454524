<template>
  <div>
    <a-card title="销售单">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px">
          <a-input-search v-model="searchForm.search" placeholder="单号, 客户编号/名称" allowClear @search="search" />
        </a-col>
        <a-col :span="24" style="margin-bottom: 12px; width: 550px">
          <a-button-group>
            <a-button @click="printOrder">打印销售单</a-button>
          </a-button-group>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button type="primary" icon="plus" style="margin: 0 8px" @click="handelAdd">新增销售单</a-button>
        </div>

        <!-- <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-range-picker @change="onChangePicker" />
        </a-col> -->
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          :scroll="{ x: 1400 }"
          :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          @change="tableChange"
        >
          <div slot="number" slot-scope="value, item">
            <a @click="detial(item)">{{ value }}</a>
          </div>

          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button v-if="['editing', 'rejected'].indexOf(item.status) !== -1" size="small" @click="edit(item)">
                编辑
              </a-button>

              <a-button
                v-if="['completed', 'approving', 'approved', 'voided'].indexOf(item.status) !== -1"
                size="small"
                @click="detial(item)"
              >
                详情
              </a-button>

              <a-popconfirm
                v-if="['editing', 'rejected'].indexOf(item.status) !== -1"
                title="确定确认销售单吗?"
                @confirm="commit(item)"
              >
                <a-button type="primary">确认</a-button>
              </a-popconfirm>

              <a-popconfirm
                v-if="['editing', 'rejected'].indexOf(item.status) !== -1"
                title="确定提交审批吗?"
                @confirm="submit(item)"
              >
                <a-button type="primary">提交审批</a-button>
              </a-popconfirm>

              <!-- <a-popconfirm
                v-if="['approving'].indexOf(item.status) !== -1"
                title="确定取消审批吗?"
                @confirm="cancelSubmit(item)"
              >
                <a-button type="danger">取消审批</a-button>
              </a-popconfirm> -->

              <a-popconfirm
                v-if="['editing', 'rejected', 'voided'].indexOf(item.status) !== -1"
                title="确定删除吗?"
                @confirm="destroy(item)"
              >
                <a-button type="danger">删除</a-button>
              </a-popconfirm>

              <a-popconfirm
                v-if="['completed', 'approved'].indexOf(item.status) !== -1"
                title="确定作废吗?"
                @confirm="voidItem(item)"
              >
                <a-button type="danger">作废</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import {
  saleOrderList,
  saleOrderDestroy,
  saleOrdersCommit,
  saleOrdersSubmit,
  saleOrdersCancelSubmit,
  saleOrdersVoid,
} from "@/api/sale";
import lodopPrintOrder from "./printOrder";

export default {
  name: "SaleRecord",
  components: {},
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
          fixed: "left",
          width: 60,
        },
        {
          title: "销售单号",
          dataIndex: "number",
          sorter: true,
          scopedSlots: { customRender: "number" },
          fixed: "left",
          width: 160,
        },
        {
          title: "状态",
          dataIndex: "status_display",
        },
        {
          title: "客户",
          dataIndex: "client_name",
        },
        {
          title: "制单人",
          dataIndex: "handler_name",
        },
        {
          title: "制单日期",
          dataIndex: "handle_time",
          width: 150,
        },
        {
          title: "交货日期",
          dataIndex: "delivery_date",
          width: 150,
        },
        {
          title: "销售总数量",
          dataIndex: "total_quantity",
          width: 120,
        },
        {
          title: "销售总金额",
          dataIndex: "total_amount",
          width: 120,
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          fixed: "right",
          width: 240,
        },
      ],
      searchForm: { page: 1, page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      visible: false,
      targetItem: {},
      form: {},

      selectedRowKeys: [],
      selectedRowItems: [],
    };
  },
  computed: {},
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      saleOrderList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    onChangePicker(date, dateString) {
      let startDate = date[0],
        endDate = date[1];
      this.searchForm.start_date = startDate ? startDate.format("YYYY-MM-DD") : undefined;
      this.searchForm.end_date = endDate ? endDate.add(1, "days").format("YYYY-MM-DD") : undefined;
      this.search();
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    edit(item) {
      this.$router.push({ path: "/sale/sale_update", query: { id: item.id } });
    },
    destroy(item) {
      saleOrderDestroy({ id: item.id }).then(() => {
        this.$message.success("删除成功");
        this.list();
      });
    },
    commit(item) {
      saleOrdersCommit({ id: item.id }).then(() => {
        this.$message.success("确认销售单成功");
        this.list();
      });
    },
    submit(item) {
      saleOrdersSubmit({ id: item.id }).then(() => {
        this.$message.success("提交审批成功");
        this.list();
      });
    },
    cancelSubmit(item) {
      saleOrdersCancelSubmit({ id: item.id }).then(() => {
        this.$message.success("取消审批成功");
        this.list();
      });
    },
    detial(item) {
      this.$router.push({ path: "/sale/sale_record_detail", query: { id: item.id } });
    },
    voidItem(item) {
      saleOrdersVoid({ id: item.id }).then(() => {
        this.$message.success("作废成功");
        this.list();
      });
    },
    handelAdd() {
      this.$router.push({ path: "/sale/sale_create" });
    },
    onSelectChange(selectedRowKeys, selectedRowItems) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRowItems = selectedRowItems;
    },
    printOrder() {
      if (this.selectedRowItems.length === 0) {
        this.$message.warning("请选择要打印的单据");
        return;
      }

      lodopPrintOrder(this.selectedRowItems, {
        title: "销售单",
        headerFields: [
          "number",
          "handle_time",
          "client",
          "handler",
          "delivery_date",
          "remark",

          // "company_order_number",
          // "product_model",
          // "product_name",
          // "inspection_date",
          // "contract_number",
          // "client_model",
          // "parameter",
          // "sales_country",
          // "order_quantity",
        ],
        tableFields: [
          "goods_name",
          "goods_number",
          "goods_barcode",
          "goods_spec",
          "sales_quantity",
          "sales_price",
          "unit_name",
          "total_amount",
        ],
        tableRows: 10,
        tableSortType: "positive",
        tableSortField: "sales_quantity",
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>
